.date-selector .centre {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
}

.date-selector ion-row {
    padding-bottom: 0;
}

.date-selector ion-select {
    display: grid;
    justify-content: end;
}