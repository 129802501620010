.header-menu ion-toolbar {
  --padding-top: 7px;
  --padding-bottom: 20px;
}

.header-menu ion-toolbar:last-of-type {
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.header-menu ion-buttons {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.header-menu ion-grid {
  padding: 0;
}

.header-menu ion-col {
  padding: 0;
}

.header-menu ion-button {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.408px;
  padding: 2px 4px;
}

.header-menu .active-button-underline {
  background-color: white;
  width: 85%;
  margin: auto;
  height: 2px;
  border-radius: 1px;
}

.header-menu .active-button-underline-hidden {
  display: none;
}

.header-menu ::-webkit-scrollbar {
  display: none;
}
