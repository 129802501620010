.mobile-tariff-item ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 100%;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
}

.tariff-item ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 100%;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  max-width: 35rem;
  font-size: 14px;
}

.tariff-value {
  font-size: 14px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}

.mobile-tariff-value {
  font-size: 16px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}
