.finance-totals ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 100%;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
}

.finance-totals ion-item .tooltip {
  padding-left: 4px;
  margin-top: 3px;
}

.finance-totals ion-label {
  max-width: fit-content;
}