.login {
  align-self: center;
  height: 100%;
  width: 100%;
}

.login ion-row {
  margin-bottom: 20px;
  align-self: center;
}

.login ion-note {
  font-size: 14px;
  margin-bottom: 2px;
}

.login ion-text {
  font-weight: 700;
  color: #233dff;
  font-size: 28px;
}

.login ion-grid {
  padding-top: 100px;
}

.password-auth {
  font-size: 16px;
  padding-bottom: 30px;
  position: relative;
  align-self: center;
}
