.date-selection .centre {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
}

.date-selection ion-row {
    padding-bottom: 30px;
}

.date-selection ion-select {
    display: grid;
    justify-content: end;
}