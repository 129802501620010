.footer-menu ion-toolbar {
    padding-top: 4px;
    padding-bottom: 10px;
    border-top: 1px solid #E3E3F0;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.09);
}

.footer-menu ion-label {
    font-size: 10px;
}

.footer-menu ion-icon {
    width: 30px;
    height: 28px;
}

.footer-menu .active-tab {
    fill: #233DFF;
    color: #233DFF;
}
