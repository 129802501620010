:root {
  --ion-safe-area-bottom: 15px;
  --ion-safe-area-top: 55px;

  --ion-default-font: Poppins, "Helvetica Neue", sans-serif !important;

  --ion-color-primary: #233dff;
  --ion-color-primary-rgb: 35, 61, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #233dff;
  --ion-color-primary-tint: #233dff;

  --ion-color-secondary: #f4f6fc;
  --ion-color-secondary-rgb: 0, 102, 0;
  --ion-color-secondary-contrast: #050505;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #f4f6fc;
  --ion-color-secondary-tint: #f4f6fc;

  --ion-color-tertiary: #50ab64;
  --ion-color-tertiary-rgb: 0, 102, 0;
  --ion-color-tertiary-contrast: #f4f6fc;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #50ab64;
  --ion-color-tertiary-tint: #50ab64;
}

@media (max-width: 1000px) {
  ion-content::part(scroll) {
    padding: 25px 15px;
  }
}
