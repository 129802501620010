.login {
    max-width: 500px;
    align-self: center;
}

.login ion-row {
    margin-bottom: 20px;
    align-self: center;
}

.login ion-note {
    font-size: 14px;
    margin-bottom: 2px;
}

.login ion-text {
    font-weight: 700;
    color: #233DFF;
    font-size: 28px;
}

.login ion-grid {
    padding-top: 100px;
}

.email-login-link {
    font-size: 16px;
    padding-bottom: 30px;
    position: relative;
    align-self: center;
}